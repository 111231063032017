


// import React, { useState, useEffect } from 'react';
// import Sidebar from '../Admin/Sidebar';
// import axios from 'axios';
// import { Table, Button, Form, Card, Row, Col, Modal } from 'react-bootstrap';
// import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
// import './Cincinnati.css'
// import CommentModal from './CommentModal';
// import ReplyModal from './ReplyModal ';



// const Cincinnati = () => {
//   const [filteredData, setFilteredData] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [acceptanceStatus, setAcceptanceStatus] = useState(new Map());
//   const [selectedBid, setSelectedBid] = useState('');
//   const [status, setStatus] = useState('');
//   const [selectedRequestType, setSelectedRequestType] = useState('');
//   const [comment, setComment] = useState('');
//   const [submittedData, setSubmittedData] = useState([]);
//   const [submittedDataa, setSubmittedDataa] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [submittedBids, setSubmittedBids] = useState([]);
//   const [usersData, setUsersData] = useState([]);
//   const [submittedProducts, setSubmittedProducts] = useState([]);

// const fetchProducts = async () => {
//   try {
//     const response = await fetch('https://luxeapi.emedha.in/API/v1/api/userpd/pf');
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//   // Initialize filtered data
//     setSubmittedProducts(data);
//     setFilteredProducts(data); // Initialize filtered data
//   setUsersData(data);

//   } catch (error) {
//     console.error('Failed to fetch products:', error);
//   }
// };

// useEffect(() => {
//   fetchProducts();
// }, []);

// console.log(usersData)
//   const [bidStatuses, setBidStatuses] = useState([]); 

//   // Load submitted data from localStorage if available
//   useEffect(() => {
//     const storedData = localStorage.getItem('submittedData');
//     if (storedData) {
//       setSubmittedData(JSON.parse(storedData));
//     }
//   }, []);

//   const [results, setResults] = useState([]);

//   // Load results from localStorage on component mount
//   useEffect(() => {
//     // Load results from localStorage
//     const storedResults = localStorage.getItem('results');
//     if (storedResults) {
//       setResults(JSON.parse(storedResults));
//     }
//   }, []);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     const filteredResults = filteredData.filter(bid =>
//       bid.product.toLowerCase().includes(e.target.value.toLowerCase())
//     );
//     setFilteredData(filteredResults);
//   };

//   useEffect(() => {
//     const userData = localStorage.getItem('userData');
//     if (userData) {
//       const parsedData = JSON.parse(userData);
//       setUserEmail(parsedData.email); // Extract email from userData
//     }
//   }, []);



//   const [userEmail, setUserEmail] = useState('');
//   useEffect(() => {
//     // Retrieve and parse user data from localStorage
//     const userData = localStorage.getItem('userData');
//     if (userData) {
//       const parsedData = JSON.parse(userData);
//       setUserEmail(parsedData.email); // Extract email from userData
//     }
//   }, []);
//     // Handle form submission
  
//     const handleFormSubmitt = async (e) => {
//       e.preventDefault();
//       const createdAt = new Date().toLocaleString();
//       // const createdAt = new Date().toISOString();
//       const newRequest = {
//         requestType: selectedRequestType,
//         comment,
//         // timestamp,
//         createdAt,
//         warehouse: userEmail, // Include userEmail in the request
//       };
  
//       try {
//         // Send POST request to backend API
//          await axios.post('https://luxeapi.emedha.in/API/v1/ap/warehouse', newRequest);
//        // await axios.post('http://localhost:3009/ap/warehouse', newRequest);
        
//         // Fetch updated data
//           const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/warehouse1', {
//         //const response = await axios.get('http://localhost:3009/api/warehouse1', {
//           params: { warehouse: userEmail }
//         });
        
//         // Update state with new request
//         setSubmittedData(response.data);
        
//         // Clear form fields
//         setSelectedRequestType('');
//         setComment('');
//       } catch (error) {
//         console.error('Error submitting request:', error);
//         setError('Error submitting request, please try again later.');
//       }
//     };
   
//   useEffect(() => {
//     // Retrieve user email from localStorage
//     const userData = localStorage.getItem('userData');
//     if (userData) {
//       const parsedData = JSON.parse(userData);
//       setUserEmail(parsedData.email);
//     }
//   }, []);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
  
//   useEffect(() => {
//     if (userEmail) {
//     const fetchData = async () => {
//       try {
//         // Determine if the user is an admin
//         const isAdmin = userEmail === 'admin@gmail.com';
        
//         // Fetch data from the server with userEmail as a query parameter
//          const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/warehouse1', {
//          // const response = await axios.get('http://localhost:3009/api/warehouse1', {
//           params: { warehouse: userEmail } // Pass userEmail as a query parameter
//         });
        
//         setSubmittedData(response.data);
//  setLoading(false);
//         // Optionally update the acceptance status map if applicable
//         // const statusMap = new Map(response.data.map(bid => [bid.id, bid.isAccepted]));
//         // setAcceptanceStatus(statusMap);

//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError('Error fetching data, please try again later.');
//         setLoading(false);
//       }
//     };

   
//       fetchData();
//     }
//   }, [userEmail]);
//   console.log(submittedData) // Re-run when userEmail changes

//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     // fetch('http://localhost:3009/api/users/warehouse')
//     fetch('https://luxeapi.emedha.in/API/v1/api/users/warehouse')
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setUsers(data);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error('Error fetching users:', error);
//         setError(error);
//         setLoading(false);
//       });
//   }, []);
  
//   const [combinedData, setCombinedData] = useState([]);
//   const [selectedUsername, setSelectedUsername] = useState('');
//   const [selectedUser, setSelectedUser] = useState('');
//   const [userdata, setUserdata] = useState(null);

//   const handleUserChange = async (event) => {
//     const username = event.target.value;
//     setSelectedUser(username);
//     console.log(username);

//     if (username) {
//       try {
//         // Make an API call to fetch user data
//         const response = await axios.get(`https://luxeapi.emedha.in/API/v1/api/userdata/ta?username=${username}`);
//        //  const response = await axios.get(`http://localhost:3009/api/userdata/ta?username=${username}`);
//         setUserdata(response.data);
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     } else {
//       setUserdata(null);
//     }
//   };
//   console.log(userdata)
// console.log(selectedUser)
 
//    const isAdmin = userEmail === 'admin@gmail.com';

//    const [bidStatus, setBidStatus] = useState({});

//    useEffect(() => {
//      if (isAdmin) {
//        axios.get('https://luxeapi.emedha.in/API/v1/api/bidstatus', { params: { warehouse: userEmail } })
//          .then(response => {
//            const statusMap = response.data.reduce((acc, bid) => {
//              acc[bid.bidId] = bid.status;
//              return acc;
//            }, {});
//            setBidStatus(statusMap);
          
//          })
//          .catch(error => console.error('Error fetching bid status:', error));
//      }
//    }, [isAdmin, userEmail]);

//    const [selectedBidId, setSelectedBidId] = useState(null);
//    const [actionType, setActionType] = useState(null); // 'accept', 'reject', or null

//    const handleAction = (pId, status) => {
//      //axios.post('http://localhost:3009/ap/biddstatus', { pId, status, warehouse: userEmail })
//      axios.post('https://luxeapi.emedha.in/API/v1/ap/biddstatus', { pId, status, warehouse: userEmail })
//        .then(() => {
//          setBidStatus(prev => ({ ...prev, [pId]: status }));
//          setSelectedBidId(pId);
//          setActionType(status)
//          fetchProducts()
//        })
//        .catch(error => console.error('Error updating bid status:', error));
//    };



  
//  console.log(filteredProducts);
//  // const isAdmin = userRole === 'admin'; 
//   const [comments, setComments] = useState([]);
//   const [selectedProductId, setSelectedProductId] = useState(null);
//   const [newComment, setNewComment] = useState('');
//   const [reply, setReply] = useState('');
//  const [showModal, setShowModal] = useState(false);
 
//  const warehouseEmail = sessionStorage.getItem('warehouseEmail');
//  const userType=sessionStorage.getItem('usertype')
 
//  console.log(warehouseEmail)
//  console.log(userType)

//  const [lastSelectedCommentId, setLastSelectedCommentId] = useState(null);

//  const fetchComments = async (productId) => {
//   try {
//     const response = await axios.get(`https://luxeapi.emedha.in/API/v1/comments/${productId}`, {
//       params: { warehouseEmail, userType }
//     });

//     const commentsData = response.data;
    
//     if (commentsData.length > 0) {
//       // Assuming comments are sorted by createdAt or similar
//       const latestCommentId = commentsData[commentsData.length - 1].commentId;
//       setLastSelectedCommentId(latestCommentId);
//     }

//     setComments(commentsData);
//     // setComments(response.data);
  
//     setSelectedProductId(productId);
//     setShowCommentModal(true);
//   } catch (err) {
//     console.error('Error fetching comments:', err);
//   }
// };

// console.log(comments)

// const handleCommentSubmit = async () => {
//   try {
//    await axios.post('https://luxeapi.emedha.in/API/v1/comments', {
//  //  await axios.post('http://localhost:3009/comments', {
//       productId: selectedProductId,
//       warehouseEmail: warehouseEmail,
//       comment: newComment,
//     });
//     setNewComment('');
//     // Refetch comments after adding a new one
//     const response = await axios.get(`https://luxeapi.emedha.in/API/v1/comments/${selectedProductId}`, {
//       params: {
//         warehouseEmail: warehouseEmail,
//         userType: userType,
//       },
//     });
//     setComments(response.data);
//   } catch (error) {
//     console.error('Error submitting comment:', error);
//   }
// };

// const handleReplySubmit = async () => {
//   try {
//     if (!lastSelectedCommentId || !reply) return;

//     await axios.post('https://luxeapi.emedha.in/API/v1/reply', {
//       commentId: lastSelectedCommentId, // Reply to the latest comment
//       reply: reply,
//       warehouseEmail: warehouseEmail,
//     });

//     setReply('');
//     setShowReplyModal(false);

//     // Refetch comments to include the new reply
//     await fetchComments(selectedProductId); // Ensure you pass the right productId

//   } catch (error) {
//     console.error('Error submitting reply:', error);
//   }
// };
//   const [showCommentModal, setShowCommentModal] = useState(false);
//   const [selectedCommentId, setSelectedCommentId] = useState(null);
//   const [showReplyModal, setShowReplyModal] = useState(false);
//   const [filteredUsers, setFilteredUsers] = useState([]);

//   useEffect(() => {
//     // Retrieve usertype from sessionStorage
//     const usertype = sessionStorage.getItem('usertype');
// console.log(usertype)
//     // Filter users based on usertype
//     if (usertype === 'Admin') {
//       setFilteredUsers(users);
//     } else if (usertype === 'WareHouse') {
//       const warehouseUsers = users.filter(user => user.usertype === 'WareHouse');
//       setFilteredUsers(warehouseUsers);
//     }
//   }, []);

//   const [show, setShow] = useState(false);
//   const [currentImages, setCurrentImages] = useState([]);

//   const handlleShow = (images) => {
//     setCurrentImages(images);
//     setShow(true);
//   };

//   const handlleClose = () => setShow(false);

//   const usertype=sessionStorage.getItem('usertype')
//   console.log(usertype)
//   console.log(comments)
//   const renderComments = () => {
//     if (!comments || comments.length === 0) {
//       return <div>No comments available.</div>;
//     }
  
//     return comments.map(comment => (
//       <div key={comment.commentId} className="comment-container">
//         {/* Display the comment */}
//         <div className="comment-bubble">
//           <strong>{comment.warehouseEmail}:</strong> {comment.comment}
//         </div>
  
//         {/* Display replies */}
//         {comment.replies && comment.replies.length > 0 && (
//           <div className="reply-list">
//             {comment.replies.map(reply => (
//               <div key={reply.replyId} className="reply-bubble">
//                 <strong>{reply.replyEmail}:</strong> {reply.replyComment}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     ));
//   };
//   const [products, setProducts] = useState([]);
//   useEffect(() => {
//     let filteredProducts = products;
  
//     if (searchTerm) {
//       filteredProducts = filteredProducts.filter(product =>
//         product.productName.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }
  
//     if (usertype === 'WareHouse') {
//       filteredProducts = filteredProducts.filter(product => product.warehouse === userEmail);
//     } else if (usertype === 'Admin' && setSelectedUser) {
//       filteredProducts = filteredProducts.filter(product => product.warehouse === setSelectedUser);
//     }
  
//     setFilteredData(filteredProducts);
//   }, [products, searchTerm, usertype, setSelectedUser, userEmail]);
  
//   const getUsernameFromEmail = (email) => {
//     if (!email) return '';
//     const username = email.split('@')[0];
//     return username.charAt(0).toUpperCase() + username.slice(1);
//   };
  
//   return (
//     <>
//       <Sidebar />
//       <div id='main' >
//         <>
//         <Row>
//           <Col>
//         {usertype ==="Admin" && (
//           <h4 className='text-start'>Welcome <big style={{color:'black'}}>{usertype} </big> </h4>
//         )}
//         {usertype === 'WareHouse' && (
//         <h4 className='text-start'>Welcome  <big style={{color:'black'}}>{getUsernameFromEmail(warehouseEmail)}</big> {usertype}</h4>
//       )}
//       </Col>
//       {/* <Col>
//       {usertype ==="Admin" && (
//         <h4 className='text-end'>Warehouse</h4> 
//       )}
      
//       </Col> */}
//       </Row>
//         </>
//         {/* Search input */}
//         <div className="row justify-content-center mb-3 p-3 m-3" >
//           <div className="col-lg-6">Product Name
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search by Product Name..."
//               value={searchTerm}
//               onChange={handleSearch}
//             />
//           </div>
         
//           <div className="col-lg-6">
//           {usertype === 'Admin' ? (
//             <>
//       <label htmlFor="warehouse">Warehouse</label>
//       <select className="form-control" id="warehouse" name="warehouse" required  onChange={handleUserChange}>
//         <option value="">Select User Name</option>
        
//         {users.map((user, index) => (
//           <option key={index} value={user.email}>
//             {user.username}
//           </option>
//         ))}
//       </select>
//       </>
//       ) : null}
//     </div>



//         </div>
   

//         {/* Table rendering */}
//         <div className="row justify-content-center mt-4 p-3 m-2 ">
//           <div className="col-lg-12">
          
// <div className='mt-4'>
//   <>
//   {/* this table display only in warehouse*/}
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <div className="alert alert-danger">{error}</div>
//       ) : userType === 'WareHouse' ? (
//         <>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>pId</th>
//                 <th>Product Name</th>
//                 <th>Product Category Name</th>
//                 <th>Product Color/Design</th>
//                 <th>Block No</th>
//                 <th>No: of Bundle</th>
//                 <th>UOM</th>
//                 <th>Thickness</th>
//                 <th>Dimension (In/cm)</th>
//                 <th>Length</th>
//                 <th>Width</th>
//                 <th>Quantity</th>
//                 <th>Note</th>
//                 <th>Availability</th>
//                 <th>Start Bid Date</th>
//                 <th>Expire Bid Date</th>
//                 <th>Price/Sft</th>
//                 <th>Image</th>
//                 <th>Status/Action</th>
//                 <th>Comment</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredProducts.map((product, index) => (
//                 <tr key={product.id}>
//                   <td>{index + 1}</td>
//                   <td>{product.Id}</td>
//                   <td>{product.productName}</td>
//                   <td>{product.product}</td>
//                   <td>{product.productCategory}</td>
//                   <td>{product.blockno}</td>
//                   <td>{product.buildno}</td>
//                   <td>{product.uom}</td>
//                   <td>{product.thikness}</td>
//                   <td>{product.dimension}</td>
//                   <td>{product.dimensionType}</td>
//                   <td>{product.dimensionUnit}</td>
//                   <td>{product.quantity}</td>
//                   <td>{product.note}</td>
//                   <td>{product.availability === 1 ? 'Yes' : 'No'}</td>
//                   <td>{product.startBidDate ? new Date(product.startBidDate).toLocaleString() : 'Not selected'}</td>
//                   <td>{product.expireBidDate ? new Date(product.expireBidDate).toLocaleString() : 'Not selected'}</td>
//                   <td>{product.price}</td>
//                   <td>
//                     <Button
//                       variant="primary"
//                       onClick={() => handlleShow([product.image1, product.image2, product.image3, product.image4, product.image5, product.image6, product.image7, product.image8, product.image9, product.image10])}
//                     >
//                       Show Gallery
//                     </Button>
//                   </td>
//                   <td style={{ textAlign: 'center' }}>
//                     {usertype === 'Admin' ? (
//                       product.bidStatus === 'accept' ? (
//                         <Button variant="success" disabled>Accepted</Button>
//                       ) : product.bidStatus === 'reject' ? (
//                         <Button variant="danger" disabled>Rejected</Button>
//                       ) : (
//                         <Button variant='info' disabled>Pending</Button> // Default status if neither 'accept' nor 'reject'
//                       )
//                     ) : (
//                       product.bidStatus === 'accept' ? (
//                         <Button variant="success" disabled>Accepted</Button>
//                       ) : product.bidStatus === 'reject' ? (
//                         <Button variant="danger" disabled>Rejected</Button>
//                       ) : (
//                         <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
//                           <Button variant="success" onClick={() => handleAction(product.Id, 'accept')}>
//                             <FaCheckCircle className="tick-icon accept" />
//                           </Button>
//                           <Button variant="danger" onClick={() => handleAction(product.Id, 'reject')}>
//                             <FaTimesCircle className="tick-icon reject" style={{ color: 'red' }} />
//                           </Button>
//                         </div>
//                       )
//                     )}
//                   </td>

//                   <td>
//                     <Button onClick={() => { fetchComments(product.Id); setSelectedProductId(product.Id) }}>
//                       Show Comments
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </>
//       ) : (
//         <div></div>
//       )}


// {/* this table display only in Admin */}
// <div className='mt-4'>
//       <h3>Product Details</h3>
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <div className="alert alert-danger">{error}</div>
//       ) : userType === 'Admin' ? ( // Check if user type is 'WareHouse'
       
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>pId</th>
//                 <th>Product Name</th>
//                 <th>Product Category Name</th>
//                 <th>Product Color/Design</th>
//                 <th>Block No</th>
//                 <th>No.of Bundle</th>
//                 <th>UOM</th>
//                 <th>Thickness</th>
//                 <th>Dimension (In/cm)</th>
//                 <th>Length</th>
//                 <th>Width</th>
//                 <th>Quantity(SFT)</th>
//                 <th>Note</th>
//                 <th>Availability</th>
//                 <th>Start Bid Date</th>
//                 <th>Expire Bid Date</th>
//                 <th>Price/Sft</th>
//                 <th>Image</th>
//                 <th>Status/Action</th>
//                 <th>Comment</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredProducts.filter((item => item.warehouse === selectedUser || item.bidStatus === null)).map((product, index) => (
//                 <tr key={product.id}>
//                   <td>{index + 1}</td>
//                   <td>{product.Id}</td>
//                   <td>{product.productName}</td>
//                   <td>{product.product}</td>
//                   <td>{product.productCategory}</td>
//                   <td>{product.blockno}</td>
//                   <td>{product.buildno}</td>
//                   <td>{product.uom}</td>
//                   <td>{product.thikness}</td>
//                   <td>{product.dimension}</td>
//                   <td>{product.dimensionType}</td>
//                   <td>{product.dimensionUnit}</td>
//                   <td>{product.quantity}</td>
//                   <td>{product.note}</td>
//                   <td>{product.availability === 1 ? 'Yes' : 'No'}</td>
//                   <td>{product.startBidDate ? new Date(product.startBidDate).toLocaleString() : 'Not selected'}</td>
//                   <td>{product.expireBidDate ? new Date(product.expireBidDate).toLocaleString() : 'Not selected'}</td>
//                   <td>{product.price}</td>
//                   <td>
//                     <Button
//                       variant="primary"
//                       onClick={() => handlleShow([product.image1, product.image2, product.image3, product.image4, product.image5, product.image6, product.image7, product.image8, product.image9, product.image10])}
//                     >
//                       Show Gallery
//                     </Button>
//                   </td>
//                   <td style={{ textAlign: 'center' }}>
//                     {usertype === 'Admin' ? (
//                       product.bidStatus === 'accept' ? (
//                         <Button variant="success" disabled>Accepted</Button>
//                       ) : product.bidStatus === 'reject' ? (
//                         <Button variant="danger" disabled>Rejected</Button>
//                       ) : (
//                         <Button variant='info' disabled>Pending</Button> // Default status if neither 'accept' nor 'reject'
//                       )
//                     ) : (
//                       product.bidStatus === 'accept' ? (
//                         <Button variant="success" disabled>Accepted</Button>
//                       ) : product.bidStatus === 'reject' ? (
//                         <Button variant="danger" disabled>Rejected</Button>
//                       ) : (
//                         <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
//                           <Button variant="success" onClick={() => handleAction(product.Id, 'accept')}>
//                             <FaCheckCircle className="tick-icon accept" />
//                           </Button>
//                           <Button variant="danger" onClick={() => handleAction(product.Id, 'reject')}>
//                             <FaTimesCircle className="tick-icon reject" style={{ color: 'red' }} />
//                           </Button>
//                         </div>
//                       )
//                     )}
//                   </td>
//                   <td>
//                     <Button onClick={() => { fetchComments(product.Id); setSelectedProductId(product.Id) }}>
//                       Show Comments
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
        
//       ) : (
//         <div></div>
//       )}
//     </div>


//       </>
//     </div>

//           </div>
//         </div>
//         <Modal show={show} onHide={handlleClose} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Image Gallery</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="d-flex flex-wrap">
//             {currentImages.map((image, index) => (
//               <div key={index} className="p-2" style={{ maxWidth: '200px' }}>
//                 <img
//                   src={image}
//                   alt={`Image ${index + 1}`}
//                   className="img-fluid"
//                   style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}
//                 />
//               </div>
//             ))}
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handlleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//         {/* Display message when no matching bids */}
//         {filteredData.length === 0 && searchTerm && (
//           <div className="row justify-content-center mt-4">
//             <div className="col-lg-6">
//               <div className="alert alert-info text-center" role="alert">
//                 No bids found.
//               </div>
//             </div>
//           </div>
//         )}

// {/* Only warehouse to see there data  */}

//         {/* Form to Raise a Request */}
      
          
          
     
//             <div className='mt-4'>
           
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <div className="alert alert-danger">{error}</div>
//       ) : userType === 'WareHouse' ? (
//         <>
//          <h1>Raise a Request</h1>
//           <Form onSubmit={handleFormSubmitt}>
//             <Form.Group controlId="formRequestType">
//               <Form.Label>Select Request Type</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={selectedRequestType}
//                 onChange={(e) => setSelectedRequestType(e.target.value)}
//               >
//                 <option value="">Select...</option>
//                 <option value="Re Order">Re Order</option>
//                 <option value="Product Concern">Product Concern</option>
//                 <option value="General">General</option>
//               </Form.Control>
//             </Form.Group>
//             {selectedRequestType && (
//               <Form.Group controlId="formComment">
//                 <Form.Label>Comment</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   value={comment}
//                   onChange={(e) => setComment(e.target.value)}
//                   placeholder="Enter your comment here"
//                 />
//               </Form.Group>
//             )}
//             <Button className='mt-3' variant="primary" type="submit">
//               Submit Request
//             </Button>
//           </Form>
//           {submittedData && submittedData.length > 0 ? (
//             submittedData.map((request, index) => (
//               <Card className="mb-2" key={index}>
//                 <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
//                   <Row>
//                     <Col style={{ color: 'Red' }}>
//                       {request.requestType || 'N/A'}
//                     </Col>
//                     <Col className='text-end' style={{ color: 'darkgreen' }}>
//                       {request.warehouse}
//                     </Col>
//                   </Row>
//                 </Card.Header>
//                 <Card.Body>
//                   <Card.Text>
//                     <div className="mb-3 p-3 border rounded">
//                       <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
//                       <p className='text-end'><strong>Submitted At:</strong> {request.createdAt || 'N/A'}</p>
//                     </div>
//                   </Card.Text>
//                 </Card.Body>
//               </Card>
//             ))
//           ) : (
//             <div>No requests found.</div>
//           )}
//         </>
//       ) : (
//         <div></div>
//       )}
//     </div>
// <>
      

    
// {/* Only admin to see there data  */}

//       <div className='mt-4'>
//       <h3>Request Details</h3>
//       {loading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <div className="alert alert-danger">{error}</div>
//       ) : userType === 'Admin' ? ( // Check if user type is 'Admin'
//         userdata?.requests && userdata.requests.length > 0 ? (
//           userdata.requests.map((request, index) => (
//             <Card className="mb-2" key={index}>
//               <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
//                 <Row>
//                   <Col style={{ color: 'Red' }}>
//                     {request.requestType || 'N/A'}
//                   </Col>
//                   <Col className='text-end' style={{ color: 'darkgreen' }}>
//                     {request.warehouse}
//                   </Col>
//                 </Row>
//               </Card.Header>
//               <Card.Body>
//                 <Card.Text>
//                   <div className="mb-3 p-3 border rounded">
//                     <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
//                     <p className='text-end'><strong>Submitted At:</strong> {request.createdAt || 'N/A'}</p>
//                   </div>
//                 </Card.Text>
//               </Card.Body>
//             </Card>
//           ))
//         ) : (
//           <div>No requests found.</div>
//         )
//       ):(
//         <div></div>
//       )
//       }
//     </div>
  
// </>
        
//       </div>


//       <Modal show={showCommentModal} onHide={() => setShowCommentModal(false)} size="lg">
//   <Modal.Header closeButton>
//     <Modal.Title>Comments for Product {selectedProductId}</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <Form>
//       <Form.Group controlId="formComment">
//         <Form.Label>Add a Comment</Form.Label>
//         <Form.Control
//           type="text"
//           value={newComment}
//           onChange={(e) => setNewComment(e.target.value)}
//           placeholder="Add a comment"
//         />
//         <Button variant="primary" onClick={handleCommentSubmit} className="mt-2">
//           Submit Comment
//         </Button>
//       </Form.Group>
//     </Form>
//     <div className="mt-3">
//           {renderComments()}
//           <Button
//         variant="primary"
//         onClick={() => setShowReplyModal(true)}
//         // disabled={!selectedCommentId} // Disable if no comment is selected
//       >
//         Reply to Selected Comment
//       </Button>

//         </div>
//   </Modal.Body>
//   <Modal.Footer>
//     <Button variant="secondary" onClick={() => setShowCommentModal(false)}>
//       Close
//     </Button>
//   </Modal.Footer>
// </Modal>


// <Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
//   <Modal.Header closeButton>
//     <Modal.Title>Reply to Comment</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <Form>
//       <Form.Group controlId="formReply">
//         <Form.Label>Reply</Form.Label>
//         <Form.Control
//           type="text"
//           value={reply}
//           onChange={(e) => setReply(e.target.value)}
//           placeholder="Add a reply"
//         />
//         <Button variant="primary" onClick={handleReplySubmit} className="mt-2">
//           Submit Reply
//         </Button>
//       </Form.Group>
//     </Form>
//   </Modal.Body>
//   <Modal.Footer>
//     <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
//       Close
//     </Button>
//   </Modal.Footer>
// </Modal>

// </>
//   );
// };

// export default Cincinnati;



import React, { useState, useEffect } from 'react';
import Sidebar from '../Admin/Sidebar';
import axios from 'axios';
import { Table, Button, Form, Card, Row, Col, Modal } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './Cincinnati.css'
import CommentModal from './CommentModal';
import ReplyModal from './ReplyModal ';



const Cincinnati = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [acceptanceStatus, setAcceptanceStatus] = useState(new Map());
  const [selectedBid, setSelectedBid] = useState('');
  const [status, setStatus] = useState('');
  const [selectedRequestType, setSelectedRequestType] = useState('');
  const [comment, setComment] = useState('');
  const [submittedData, setSubmittedData] = useState([]);
  const [submittedDataa, setSubmittedDataa] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // Fetch data from the API
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/userbd');
  //       setFilteredData(response.data);
  //       // Initialize acceptance status based on data
  //       const statusMap = new Map(response.data.map(bid => [bid.id, bid.isAccepted]));
  //       setAcceptanceStatus(statusMap);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const [submittedBids, setSubmittedBids] = useState([]);

  const [usersData, setUsersData] = useState([]);
  const [submittedProducts, setSubmittedProducts] = useState([]);

  //09-09-2024

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         //const response = await fetch('https://luxeapi.emedha.in/API/v1/api/userpd');
//   const response = await fetch('https://luxeapi.emedha.in/API/v1/api/userpd/pf');
//  // const response = await fetch('http://localhost:3009/api/userpd/pf');
       
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
        //   setSubmittedProducts(data);
        //   setFilteredProducts(data); // Initialize filtered data
        // setUsersData(data);
       
//       } catch (error) {
//         console.error('Failed to fetch users:', error);
//       }
//     };
//     fetchProducts();
//   }, []);

const fetchProducts = async () => {
  try {
    const response = await fetch('https://luxeapi.emedha.in/API/v1/api/userpd/pf');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
  // Initialize filtered data
    setSubmittedProducts(data);
    setFilteredProducts(data); // Initialize filtered data
  setUsersData(data);

  } catch (error) {
    console.error('Failed to fetch products:', error);
  }
};
useEffect(() => {
  fetchProducts();
}, []);

console.log(usersData)
  const [bidStatuses, setBidStatuses] = useState([]); 

  // Load submitted data from localStorage if available
  useEffect(() => {
    const storedData = localStorage.getItem('submittedData');
    if (storedData) {
      setSubmittedData(JSON.parse(storedData));
    }
  }, []);

  const [results, setResults] = useState([]);

  // Load results from localStorage on component mount
  useEffect(() => {
    // Load results from localStorage
    const storedResults = localStorage.getItem('results');
    if (storedResults) {
      setResults(JSON.parse(storedResults));
    }
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filteredResults = filteredData.filter(bid =>
      bid.product.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUserEmail(parsedData.email); // Extract email from userData
    }
  }, []);



  const [userEmail, setUserEmail] = useState('');
  useEffect(() => {
    // Retrieve and parse user data from localStorage
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUserEmail(parsedData.email); // Extract email from userData
    }
  }, []);
    // Handle form submission
  
    const handleFormSubmitt = async (e) => {
      e.preventDefault();
      const createdAt = new Date().toLocaleString();
      // const createdAt = new Date().toISOString();
      const newRequest = {
        requestType: selectedRequestType,
        comment,
        // timestamp,
        createdAt,
        warehouse: userEmail, // Include userEmail in the request
      };
  
      try {
        // Send POST request to backend API
         await axios.post('https://luxeapi.emedha.in/API/v1/ap/warehouse', newRequest);
       // await axios.post('http://localhost:3009/ap/warehouse', newRequest);
        
        // Fetch updated data
          const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/warehouse1', {
        //const response = await axios.get('http://localhost:3009/api/warehouse1', {
          params: { warehouse: userEmail }
        });
        
        // Update state with new request
        setSubmittedData(response.data);
        
        // Clear form fields
        setSelectedRequestType('');
        setComment('');
      } catch (error) {
        console.error('Error submitting request:', error);
        setError('Error submitting request, please try again later.');
      }
    };
   
  useEffect(() => {
    // Retrieve user email from localStorage
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUserEmail(parsedData.email);
    }
  }, []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // useEffect(() => {
  //   if (userEmail) {
  //     const fetchResults = async () => {
  //       try {
  //         const isAdmin = userEmail === 'admin@gmail.com';
  //         // Fetch data from the server with userEmail as a query parameter
  //          const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/bidstatus', {
  //          // const response = await axios.get('http://localhost:3009/api/bidstatus', {
  //           params: { warehouse: userEmail }
  //         });
  //         setResults(response.data || []);// Store fetched bid statuses in state
  //         setLoading(false);
  //       } catch (error) {
  //         console.error('Error fetching bid statuses:', error);
  //         setError('Error fetching bid statuses, please try again later.');
  //         setLoading(false);
  //       }
  //     };

  //     fetchResults();
  //   }
  // }, [userEmail]);
  // console.log(results)


 

  useEffect(() => {
    if (userEmail) {
    const fetchData = async () => {
      try {
        // Determine if the user is an admin
        const isAdmin = userEmail === 'admin@gmail.com';
        
        // Fetch data from the server with userEmail as a query parameter
         const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/warehouse1', {
         // const response = await axios.get('http://localhost:3009/api/warehouse1', {
          params: { warehouse: userEmail } // Pass userEmail as a query parameter
        });
        
        setSubmittedData(response.data);
 setLoading(false);
        // Optionally update the acceptance status map if applicable
        // const statusMap = new Map(response.data.map(bid => [bid.id, bid.isAccepted]));
        // setAcceptanceStatus(statusMap);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data, please try again later.');
        setLoading(false);
      }
    };

   
      fetchData();
    }
  }, [userEmail]);
  console.log(submittedData) // Re-run when userEmail changes

  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    // fetch('http://localhost:3009/api/users/warehouse')
    fetch('https://luxeapi.emedha.in/API/v1/api/users/warehouse')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setUsers(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
        setError(error);
        setLoading(false);
      });
  }, []);
  
  const [combinedData, setCombinedData] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState('');


  // const handleUserChange = async (event) => {
  //   const username = event.target.value;
  //   setSelectedUsername(username);

  //   if (username) {
  //     try {
  //       const response = await axios.get(`http://localhost:3009/api/combined/${username}`);
  //       setCombinedData(response.data);
  //     } catch (error) {
  //       console.error('Error fetching combined data:', error);
  //       setCombinedData([]);
  //     }
  //   } else {
  //     setCombinedData([]);
  //   }
  // };
  const [selectedUser, setSelectedUser] = useState('');
  const [userdata, setUserdata] = useState(null);

  const handleUserChange = async (event) => {
    const username = event.target.value;
    setSelectedUser(username);
    console.log(username);

    if (username) {
      try {
        // Make an API call to fetch user data
        const response = await axios.get(`https://luxeapi.emedha.in/API/v1/api/userdata/ta?username=${username}`);
       //  const response = await axios.get(`http://localhost:3009/api/userdata/ta?username=${username}`);
        setUserdata(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    } else {
      setUserdata(null);
    }
  };
  console.log(userdata)
console.log(selectedUser)
  // useEffect(() => {
  //   // Retrieve and parse user data from localStorage
  //   const userData = localStorage.getItem('userData');
  //   if (userData) {
  //     const parsedData = JSON.parse(userData);
  //     const email = parsedData.email;
  //     const username = email.split('@')[0].toUpperCase(); // Extract and convert username to uppercase
  //     setUserEmail(username); // Set the username
  //   }
  // }, []);
   const isAdmin = userEmail === 'admin@gmail.com';

   const [bidStatus, setBidStatus] = useState({});

   useEffect(() => {
     if (isAdmin) {
       axios.get('https://luxeapi.emedha.in/API/v1/api/bidstatus', { params: { warehouse: userEmail } })
         .then(response => {
           const statusMap = response.data.reduce((acc, bid) => {
             acc[bid.bidId] = bid.status;
             return acc;
           }, {});
           setBidStatus(statusMap);
          
         })
         .catch(error => console.error('Error fetching bid status:', error));
     }
   }, [isAdmin, userEmail]);

   const [selectedBidId, setSelectedBidId] = useState(null);
   const [actionType, setActionType] = useState(null); // 'accept', 'reject', or null

   const handleAction = (pId, status) => {
     //axios.post('http://localhost:3009/ap/biddstatus', { pId, status, warehouse: userEmail })
     axios.post('https://luxeapi.emedha.in/API/v1/ap/biddstatus', { pId, status, warehouse: warehouseEmail })
       .then(() => {
        fetchBidStatuses();
         setSelectedBidId(pId);
         setActionType(status)
         fetchProducts()
       })

       
       .catch(error => console.error('Error updating bid status:', error));
   };


  const [bidstatus, setBidstatus] = useState({});
  
  const fetchBidStatuses = async () => {
    try {
   //  const response = await fetch(`http://localhost:3009/ap/bidstatus?warehouse=${warehouseEmail}`);
        const response = await fetch(`https://luxeapi.emedha.in/API/v1/ap/bidstatus?warehouse=${warehouseEmail}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBidstatus(data);
    } catch (error) {
      console.error('Failed to fetch bid statuses:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchBidStatuses();
  }, []);

 console.log(filteredProducts);
 // const isAdmin = userRole === 'admin'; 
  const [comments, setComments] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [reply, setReply] = useState('');
 const [showModal, setShowModal] = useState(false);
 
 const warehouseEmail = sessionStorage.getItem('warehouseEmail');
 const userType=sessionStorage.getItem('usertype')
 
 console.log(warehouseEmail)
 console.log(userType)

 const [lastSelectedCommentId, setLastSelectedCommentId] = useState(null);

 const fetchComments = async (productId) => {
  try {
    const response = await axios.get(`https://luxeapi.emedha.in/API/v1/comments/${productId}`, {
      params: { warehouseEmail, userType }
    });

    const commentsData = response.data;
    
    if (commentsData.length > 0) {
      // Assuming comments are sorted by createdAt or similar
      const latestCommentId = commentsData[commentsData.length - 1].commentId;
      setLastSelectedCommentId(latestCommentId);
    }

    setComments(commentsData);
    // setComments(response.data);
  
    setSelectedProductId(productId);
    setShowCommentModal(true);
  } catch (err) {
    console.error('Error fetching comments:', err);
  }
};


console.log(comments)


 
const handleCommentSubmit = async () => {
  try {
   await axios.post('https://luxeapi.emedha.in/API/v1/comments', {
 //  await axios.post('http://localhost:3009/comments', {
      productId: selectedProductId,
      warehouseEmail: warehouseEmail,
      comment: newComment,
    });
    setNewComment('');
    // Refetch comments after adding a new one
    const response = await axios.get(`https://luxeapi.emedha.in/API/v1/comments/${selectedProductId}`, {
      params: {
        warehouseEmail: warehouseEmail,
        userType: userType,
      },
    });
    setComments(response.data);
  } catch (error) {
    console.error('Error submitting comment:', error);
  }
};

// const handleReplySubmit = async () => {
//   try {
//     await axios.post(`http://localhost:3009/reply`, {
//       commentId: selectedCommentId,
//       reply: reply,
//       warehouseEmail: warehouseEmail,
//     });
//     setReply('');
//     setShowReplyModal(false);
//     // Refetch comments after replying
//     const response = await axios.get(`http://localhost:3009/comments/${selectedProductId}`, {
//       params: {
//         warehouseEmail: warehouseEmail,
//         userType: userType,
//       },
//     });
//     setComments(response.data);
//   } catch (error) {
//     console.error('Error submitting reply:', error);
//   }
// };


const handleReplySubmit = async () => {
  try {
    if (!lastSelectedCommentId || !reply) return;

    await axios.post('https://luxeapi.emedha.in/API/v1/reply', {
      commentId: lastSelectedCommentId, // Reply to the latest comment
      reply: reply,
      warehouseEmail: warehouseEmail,
      
    });

    setReply('');
    setShowReplyModal(false);

    // Refetch comments to include the new reply
    await fetchComments(selectedProductId); // Ensure you pass the right productId

  } catch (error) {
    console.error('Error submitting reply:', error);
  }
};



  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [showReplyModal, setShowReplyModal] = useState(false);


const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    // Retrieve usertype from sessionStorage
    const usertype = sessionStorage.getItem('usertype');
console.log(usertype)
    // Filter users based on usertype
    if (usertype === 'Admin') {
      setFilteredUsers(users);
    } else if (usertype === 'WareHouse') {
      const warehouseUsers = users.filter(user => user.usertype === 'WareHouse');
      setFilteredUsers(warehouseUsers);
    }
  }, []);

  const [show, setShow] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);

  const handlleShow = (images) => {
    setCurrentImages(images);
    setShow(true);
  };

  const handlleClose = () => setShow(false);

  const usertype=sessionStorage.getItem('usertype')


  console.log(usertype)
  console.log(comments)

  // const renderComments = (comments) => {
  //   if (!comments || comments.length === 0) {
  //     return <div>No comments available.</div>;
  //   }
  
  //   return comments.map(comment => (
  //     <div key={comment.commentId} style={{ marginBottom: '10px' }}>
  //       <div>
  //         <strong>{comment.warehouseEmail}:</strong> {comment.comment}
  //       </div>
  //       {comment.replies && comment.replies.length > 0 && (
  //         <ul style={{ marginLeft: '20px' }}>
  //           {comment.replies.map(reply => (
  //             <li key={reply.replyId}>
  //               <strong>{reply.replyEmail}:</strong> {reply.replyComment}
  //             </li>
  //           ))}
  //         </ul>
  //       )}
  //       {(userType === 'Admin' || comment.warehouseEmail === warehouseEmail) && (
  //         <Button variant="link" onClick={() => {
  //           setSelectedCommentId(comment.commentId);
  //           setShowReplyModal(true);
  //         }}>
  //           Reply
  //         </Button>
  //       )}
  //     </div>
  //   ));
  // };
  
  
  // const renderComments = (comments) => {
  //   if (!comments || comments.length === 0) {
  //     return <div>No comments available.</div>;
  //   }
  
  //   return comments.map(comment => (
  //     <div key={comment.commentId} className="comment-container">
  //       {/* Display the comment */}
  //       <div className="comment-bubble">
  //         <strong>{comment.warehouseEmail}:</strong> {comment.comment}
  //       </div>
  
  //       {/* Display replies */}
  //       {comment.replies && comment.replies.length > 0 && (
  //         <div className="reply-list">
  //           {comment.replies.map(reply => (
  //             <div key={reply.replyId} className="reply-bubble">
  //               <strong>{reply.replyEmail}:</strong> {reply.replyComment}
  //             </div>
  //           ))}
  //         </div>
  //       )}
  
  //       {/* Reply button */}
  //       {(userType === 'Admin' || comment.warehouseEmail === warehouseEmail) && (
  //         <Button variant="link" onClick={() => {
  //           setSelectedCommentId(comment.commentId);
  //           setShowReplyModal(true);
  //         }}>
  //           Reply
  //         </Button>
  //       )}
  //     </div>
  //   ));
  // };
  
  const formatDateTime = (dateTime) => {
    if (!dateTime) return '';
    return dateTime.substring(0, 16); // Extracts 'YYYY-MM-DDTHH:MM'
};


  const renderComments = () => {
    if (!comments || comments.length === 0) {
      return <div>No comments available.</div>;
    }
    
  // Helper function to format dateTime
  const formatDateTime = (dateTime) => {
    if (!dateTime) return '';
    return dateTime.substring(0, 16); // Extracts 'YYYY-MM-DDTHH:MM'
};
    return comments.map(comment => (
      <div key={comment.commentId} className="comment-container">
        {/* Display the comment */}
        <div className="comment-bubble">
          <strong>{comment.warehouseEmail}:</strong> {comment.comment} {formatDateTime(comment.createdAt)}
        </div>
  
        {/* Display replies */}
        {comment.replies && comment.replies.length > 0 && (
          <div className="reply-list">
            {comment.replies.map(reply => (
              <div key={reply.replyId} className="reply-bubble">
                <strong>{reply.replyEmail}:</strong> {reply.replyComment} {formatDateTime(reply.createdAt)}
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let filteredProducts = products;
  
    if (searchTerm) {
      filteredProducts = filteredProducts.filter(product =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (usertype === 'WareHouse') {
      filteredProducts = filteredProducts.filter(product => product.warehouse === userEmail);
    } else if (usertype === 'Admin' && setSelectedUser) {
      filteredProducts = filteredProducts.filter(product => product.warehouse === setSelectedUser);
    }
  
    setFilteredData(filteredProducts);
  }, [products, searchTerm, usertype, setSelectedUser, userEmail]);
  
  const getUsernameFromEmail = (email) => {
    if (!email) return '';
    const username = email.split('@')[0];
    return username.charAt(0).toUpperCase() + username.slice(1);
  };

  const uniqueProducts = Array.from(
    new Map(filteredProducts.map(product => [product.Id, product])).values()
  );

  const getBidStatusForCurrentWarehouse = (productId) => {
    const productBidStatuses = bidstatus.filter(status => status.pId === productId);
    const statusByWarehouse = productBidStatuses.find(status => status.warehouse === warehouseEmail);
    return statusByWarehouse ? statusByWarehouse.status : null;
  };

  const getBidStatusByOthers = (productId) => {
    const productBidStatuses = bidstatus.filter(status => status.pId === productId);
    return productBidStatuses.some(status => status.status === 'accept' && status.warehouse !== warehouseEmail);
  };
  

  const filteredSubmittedData = submittedData.filter(request => request.warehouse === warehouseEmail);

  return (
    <>
      <Sidebar />
      <div id='main' >
        <>
        <Row>
          <Col>
        {usertype ==="Admin" && (
          <h4 className='text-start'>Welcome <big style={{color:'black'}}>{usertype} </big> </h4>
        )}
        {usertype === 'WareHouse' && (
        <h4 className='text-start'>Welcome  <big style={{color:'black'}}>{getUsernameFromEmail(warehouseEmail)}</big> {usertype}</h4>
      )}
      </Col>
      {/* <Col>
      {usertype ==="Admin" && (
        <h4 className='text-end'>Warehouse</h4> 
      )}
      
      </Col> */}
      </Row>
        </>
        {/* Search input */}
        <div className="row justify-content-center mb-3 p-3 m-3" >
          <div className="col-lg-6">Product Name
            <input
              type="text"
              className="form-control"
              placeholder="Search by Product Name..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
         
          <div className="col-lg-6">
          {usertype === 'Admin' ? (
            <>
      <label htmlFor="warehouse">Warehouse</label>
      <select className="form-control" id="warehouse" name="warehouse" required  onChange={handleUserChange}>
        <option value="">Select User Name</option>
        
        {users.map((user, index) => (
          <option key={index} value={user.email}>
            {user.username}
          </option>
        ))}
      </select>
      </>
      ) : null}
    </div>



        </div>
   

        {/* Table rendering */}
        <div className="row justify-content-center mt-4 p-3 m-2 ">
          <div className="col-lg-12">
     
<div className='mt-4'>
  <>
  {/* this table display only in warehouse*/}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : userType === 'WareHouse' ? (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>pId</th>
                <th>Product Name</th>
                <th>Product Category Name</th>
                <th>Product Color/Design</th>
                <th>Block No</th>
                <th>No: of Bundle</th>
                <th>UOM</th>
                <th>Thickness</th>
                <th>Dimension (In/cm)</th>
                <th>Length</th>
                <th>Width</th>
                <th>Quantity</th>
                <th>Note</th>
                <th>Availability</th>
                <th>Start Bid Date</th>
                <th>Expire Bid Date</th>
                <th>Price/Sft</th>
                <th>Image</th>
                <th>Status/Action</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
        {uniqueProducts.map((product, index) => {

const currentStatus = getBidStatusForCurrentWarehouse(product.Id);
const statusByOthers = getBidStatusByOthers(product.Id);
            // Find bid status for the current product and warehouse
            const productBidStatuses = bidstatus.filter(status => status.pId === product.Id);
      
            // Determine if the product has been accepted by any warehouse
            const isAccepted = productBidStatuses.some(status => status.status === 'accept',product.Id);
            
            // Check if the product has been rejected by the current warehouse
            const isRejectedByCurrentWarehouse = productBidStatuses.some(
              status => status.status === 'reject' && status.warehouse === warehouseEmail
            );
            
            // Determine if the action buttons should be shown
            const showButtons = !isAccepted && !isRejectedByCurrentWarehouse;
          return (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>{product.Id}</td>
              <td>{product.productName}</td>
              <td>{product.product}</td>
              <td>{product.productCategory}</td>
              <td>{product.blockno}</td>
              <td>{product.buildno}</td>
              <td>{product.uom}</td>
              <td>{product.thikness}</td>
              <td>{product.dimension}</td>
              <td>{product.dimensionType}</td>
              <td>{product.dimensionUnit}</td>
              <td>{product.quantity}</td>
              <td>{product.note}</td>
              <td>{product.availability === 1 ? 'Yes' : 'No'}</td>
              <td>{product.startBidDate ? new Date(product.startBidDate).toLocaleString() : 'Not selected'}</td>
              <td>{product.expireBidDate ? new Date(product.expireBidDate).toLocaleString() : 'Not selected'}</td>
              <td>{product.price}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handlleShow([product.image1, product.image2, product.image3, product.image4, product.image5, product.image6, product.image7, product.image8, product.image9, product.image10])}
                >
                  Show Gallery
                </Button>
              </td>
              {/* <td style={{ textAlign: 'center' }}>
            {isAccepted ? (
              <Button variant="success" disabled>Accepted
              
              </Button>
            ) : isRejectedByCurrentWarehouse ? (
              <Button variant="danger" disabled>Rejected</Button>
            ) : showButtons ? (
              <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                <Button variant="success" onClick={() => handleAction(product.Id, 'accept')}>
                  <FaCheckCircle className="tick-icon accept" />
                </Button>
                <Button variant="danger" onClick={() => handleAction(product.Id, 'reject')}>
                  <FaTimesCircle className="tick-icon reject" style={{ color: 'red' }} />
                </Button>
              </div>
            ) : (
              <Button variant='info' disabled>Pending</Button>
            )}
          </td> */}
              <td style={{ textAlign: 'center' }}>
                    {currentStatus === 'accept' ? (
                      <Button variant="success" disabled>Accepted by you</Button>
                    ) : currentStatus === 'reject' ? (
                      <Button variant="danger" disabled>Rejected by you</Button>
                    ) : statusByOthers ? (
                      <Button variant="info" disabled>Accepted by others</Button>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                        <Button variant="success" onClick={() => handleAction(product.Id, 'accept')}>
                          <FaCheckCircle className="tick-icon accept" />
                        </Button>
                        <Button variant="danger" onClick={() => handleAction(product.Id, 'reject')}>
                          <FaTimesCircle className="tick-icon reject" style={{ color: 'red' }} />
                        </Button>
                      </div>
                    )}
                  </td>
              <td>
                <Button onClick={() => { fetchComments(product.Id); setSelectedProductId(product.Id) }}>
                  Show Comments
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
          </Table>
        </>
      ) : (
        <div></div>
      )}


{/* this table display only in Admin */}
<div className='mt-4'>
      <h3>Product Details</h3>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : userType === 'Admin' ? ( // Check if user type is 'WareHouse'
       
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>pId</th>
                <th>Product Name</th>
                <th>Product Category Name</th>
                <th>Product Color/Design</th>
                <th>Block No</th>
                <th>No.of Bundle</th>
                <th>UOM</th>
                <th>Thickness</th>
                <th>Dimension (In/cm)</th>
                <th>Length</th>
                <th>Width</th>
                <th>Quantity(SFT)</th>
                <th>Note</th>
                <th>Availability</th>
                <th>Start Bid Date</th>
                <th>Expire Bid Date</th>
                <th>Price/Sft</th>
                <th>Image</th>
                <th>Status/Action</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.filter((item => item.warehouse === selectedUser || item.bidStatus === null)).map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>{product.Id}</td>
                  <td>{product.productName}</td>
                  <td>{product.product}</td>
                  <td>{product.productCategory}</td>
                  <td>{product.blockno}</td>
                  <td>{product.buildno}</td>
                  <td>{product.uom}</td>
                  <td>{product.thikness}</td>
                  <td>{product.dimension}</td>
                  <td>{product.dimensionType}</td>
                  <td>{product.dimensionUnit}</td>
                  <td>{product.quantity}</td>
                  <td>{product.note}</td>
                  <td>{product.availability === 1 ? 'Yes' : 'No'}</td>
                  <td>{product.startBidDate ? new Date(product.startBidDate).toLocaleString() : 'Not selected'}</td>
                  <td>{product.expireBidDate ? new Date(product.expireBidDate).toLocaleString() : 'Not selected'}</td>
                  <td>{product.price}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handlleShow([product.image1, product.image2, product.image3, product.image4, product.image5, product.image6, product.image7, product.image8, product.image9, product.image10])}
                    >
                      Show Gallery
                    </Button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {usertype === 'Admin' ? (
                      product.bidStatus === 'accept' ? (
                        <Button variant="success" disabled>Accepted</Button>
                      ) : product.bidStatus === 'reject' ? (
                        <Button variant="danger" disabled>Rejected</Button>
                      ) : (
                        <Button variant='info' disabled>Pending</Button> // Default status if neither 'accept' nor 'reject'
                      )
                    ) : (
                      product.bidStatus === 'accept' ? (
                        <Button variant="success" disabled>Accepted</Button>
                      ) : product.bidStatus === 'reject' ? (
                        <Button variant="danger" disabled>Rejected</Button>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                          <Button variant="success" onClick={() => handleAction(product.Id, 'accept')}>
                            <FaCheckCircle className="tick-icon accept" />
                          </Button>
                          <Button variant="danger" onClick={() => handleAction(product.Id, 'reject')}>
                            <FaTimesCircle className="tick-icon reject" style={{ color: 'red' }} />
                          </Button>
                        </div>
                      )
                    )}
                  </td>
                  <td>
                    <Button onClick={() => { fetchComments(product.Id); setSelectedProductId(product.Id) }}>
                      Show Comments
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        
      ) : (
        <div></div>
      )}
    </div>


      </>
    </div>

          </div>
        </div>
        <Modal show={show} onHide={handlleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap">
            {currentImages.map((image, index) => (
              <div key={index} className="p-2" style={{ maxWidth: '200px' }}>
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="img-fluid"
                  style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        {/* Display message when no matching bids */}
        {filteredData.length === 0 && searchTerm && (
          <div className="row justify-content-center mt-4">
            <div className="col-lg-6">
              <div className="alert alert-info text-center" role="alert">
                No bids found.
              </div>
            </div>
          </div>
        )}

{/* Only warehouse to see there data  */}

        {/* Form to Raise a Request */}
      
          
          
          {/* <Form onSubmit={handleFormSubmitt}>
            <Form.Group controlId="formRequestType">
              <Form.Label>Select Request Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedRequestType}
                onChange={(e) => setSelectedRequestType(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="reorder">Reorder</option>
                <option value="productConcern">Product Concern</option>
                <option value="general">General</option>
              </Form.Control>
            </Form.Group>
            {selectedRequestType && (
              <Form.Group controlId="formComment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Enter your comment here"
                />
              </Form.Group>
            )}
            <Button className='mt-3' variant="primary" type="submit">
              Submit Request
            </Button>
          </Form> */}
            <div className='mt-4'>
           
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : userType === 'WareHouse' ? (
        <>
         <h1>Raise a Request</h1>
          <Form onSubmit={handleFormSubmitt}>
            <Form.Group controlId="formRequestType">
              <Form.Label>Select Request Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedRequestType}
                onChange={(e) => setSelectedRequestType(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="Re Order">Re Order</option>
                <option value="Product Concern">Product Concern</option>
                <option value="General">General</option>
              </Form.Control>
            </Form.Group>
            {selectedRequestType && (
              <Form.Group controlId="formComment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Enter your comment here"
                />
              </Form.Group>
            )}
            <Button className='mt-3' variant="primary" type="submit">
              Submit Request
            </Button>
          </Form>
          {submittedData && submittedData.length > 0 ? (
            submittedData.map((request, index) => (
              <Card className="mb-2" key={index}>
                <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  <Row>
                    <Col style={{ color: 'Red' }}>
                      {request.requestType || 'N/A'}
                    </Col>
                    <Col className='text-end' style={{ color: 'darkgreen' }}>
                      {request.warehouse}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="mb-3 p-3 border rounded">
                      <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
                      <p className='text-end'><strong>Submitted At:</strong> {formatDateTime(request.createdAt) || 'N/A'}</p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))
          ) : (

            <div>No requests found.</div>
          )}
        </>
      ) : (
        <div></div>
      )}
    </div>
<>
          {/* Display Request Details */}


            {/* {filteredSubmittedData && filteredSubmittedData.length > 0 ? (
            filteredSubmittedData.map((request, index) => (
              <Card className="mb-2" key={index}>
                <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  <Row>
                    <Col style={{ color: 'Red' }}>
                      {request.requestType || 'N/A'}
                    </Col>
                    <Col className='text-end' style={{ color: 'darkgreen' }}>
                      {request.warehouse}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="mb-3 p-3 border rounded">
                      <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
                      <p className='text-end'><strong>Submitted At:</strong> {formatDateTime(request.createdAt) || 'N/A'}</p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))
          ) : ( */}


          {/* <div className='mt-4'>
          <h3>Request Details</h3>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : (
            userdata?.requests && userdata.requests.length > 0 ? (
              userdata.requests.map((request, index) => (
                <Card className="mb-2" key={index}>
                  <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                    <Row>
                      <Col style={{ color: 'Red' }}>
                        {request.requestType || 'N/A'}
                      </Col>
                      <Col className='text-end' style={{ color: 'darkgreen' }}>
                        {request.warehouse}
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <div className="mb-3 p-3 border rounded">
                        <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
                        <p className='text-end'><strong>Submitted At:</strong> {request.timestamp || 'N/A'}</p>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <div>No requests found.</div>
            )
          )}
        </div> */}

    
{/* Only admin to see there data  */}

      <div className='mt-4'>
      <h3>Request Details</h3>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : userType === 'Admin' ? ( // Check if user type is 'Admin'
        userdata?.requests && userdata.requests.length > 0 ? (
          userdata.requests.map((request, index) => (
            <Card className="mb-2" key={index}>
              <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                <Row>
                  <Col style={{ color: 'Red' }}>
                    {request.requestType || 'N/A'}
                  </Col>
                  <Col className='text-end' style={{ color: 'darkgreen' }}>
                    {request.warehouse}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <div className="mb-3 p-3 border rounded">
                    <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
                    <p className='text-end'><strong>Submitted At:</strong> {formatDateTime(request.createdAt) || 'N/A'}</p>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          ))
        ) : (
          <div>No requests found.</div>
        )
      ):(
        <div></div>
      )
      }
    </div>



{/* <div className='mt-4'>
     
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : userType === 'WareHouse' ? ( // Check if user type is 'WareHouse'
        submittedData && submittedData.length > 0 ? (
          submittedData.map((request, index) => (
            <Card className="mb-2" key={index}>
              <Card.Header style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                <Row>
                  <Col style={{ color: 'Red' }}>
                    {request.requestType || 'N/A'}
                  </Col>
                  <Col className='text-end' style={{ color: 'darkgreen' }}>
                    {request.warehouse}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <div className="mb-3 p-3 border rounded">
                    <p><strong>Comment:</strong> {request.comment || 'N/A'}</p>
                    <p className='text-end'><strong>Submitted At:</strong> {request.timestamp || 'N/A'}</p>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          ))
        ) : (
          <div>No requests found.</div>
        )
      ) : (
        <div></div>
      )}
    </div> */}


        
</>
        
      </div>


      <Modal show={showCommentModal} onHide={() => setShowCommentModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Comments for Product {selectedProductId}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formComment">
        <Form.Label>Add a Comment</Form.Label>
        <Form.Control
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment"
        />
        <Button variant="primary" onClick={handleCommentSubmit} className="mt-2">
          Submit Comment
        </Button>
      </Form.Group>
    </Form>
    <div className="mt-3">
          {renderComments()}
          <Button
        variant="primary"
        onClick={() => setShowReplyModal(true)}
        // disabled={!selectedCommentId} // Disable if no comment is selected
      >
        Reply to Selected Comment
      </Button>

        </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCommentModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>


      {/* Reply Modal */}
      {/* <Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Reply to Comment</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formReply">
        <Form.Label>Reply</Form.Label>
        <Form.Control
          type="text"
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          placeholder="Add a reply"
        />
        <Button variant="primary" onClick={handleReplySubmit} className="mt-2">
          Submit Reply
        </Button>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal> */}

<Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Reply to Comment</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formReply">
        <Form.Label>Reply</Form.Label>
        <Form.Control
          type="text"
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          placeholder="Add a reply"
        />
        <Button variant="primary" onClick={handleReplySubmit} className="mt-2">
          Submit Reply
        </Button>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

</>
  );
};

export default Cincinnati;