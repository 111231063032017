import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Form, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { IoIosEye } from "react-icons/io";
import { LuClipboardEdit } from "react-icons/lu";
import { MdDeleteForever } from "react-icons/md";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import Sidebar from '../Admin/Sidebar';

const UserManagement = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile: '',
    gender: '',
    usertype: '',
    password: '',
    repassword: ''
  });
  const [errors, setErrors] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewUser, setViewUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = async () => {
    try {
     const response = await fetch('https://luxeapi.emedha.in/API/v1/api/usersd');
      //const response = await fetch('http://localhost:3009/api/usersd');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUsersData(data);
      setFilteredUsers(data); // Initialize filtered data with fetched users
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  console.log(usersData)

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    // Validation
    if (!formData.username) { newErrors.username = 'Name is required'; }
    if (!formData.mobile) { newErrors.mobile = 'Mobile number is required'; }
    if (!formData.email) { newErrors.email = 'Email is required'; }
    else if (!/\S+@\S+\.\S+/.test(formData.email)) { newErrors.email = 'Email is invalid'; }
    if (!formData.password && !editMode) { newErrors.password = 'Password is required'; }
    if (formData.password !== formData.repassword) {
      newErrors.repassword = 'Passwords do not match';
    }
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        const method = editMode ? 'PUT' : 'POST'; 
        const url = editMode
            ? `https://luxeapi.emedha.in/API/v1/api/user/${usersData[editUserId].id}` // Use the editUserId for updates
         : 'https://luxeapi.emedha.in/API/v1/api/user'; // Endpoint for adding users
        // ? `http://localhost:3009/api/user/${usersData[editUserId].id}` // Use the editUserId for updates
       //  : 'http://localhost:3009/api/user'; // Endpoint for adding users

  
      // Use PUT for updates and POST for creation
      console.log('URL:', url);
      console.log('Method:', method);
      console.log('Form Data:', formData);
      console.log('Edit User ID:', editUserId);


        const response = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        //console.log(formData)
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save user');
        }
       // console.log(formData)
        // Clear form data and exit modal after successful submission
        setFormData({
          username: '',
          email: '',
          mobile: '',
          gender: '',
          usertype: '',
          password: '',
          repassword: ''
        });
        setShowModal(false);
        setEditMode(false);
        setEditUserId(null);
        alert(editMode ? 'User successfully updated!' : 'User successfully added!');
  
        await fetchUsers(); // Fetch the updated list of users
      } catch (error) {
        console.error('Failed to save user:', error);
        alert('Failed to save user.');
      }
    }
  };
 
  const handleShowModal = (user = null) => {
    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        mobile: user.mobile || '',
        gender: user.gender || '',
        usertype: user.usertype || '',
        password: '',
        repassword: ''
      });
      setEditMode(true);
      setEditUserId(user.id);
    } else {
      setFormData({
        username: '',
        email: '',
        mobile: '',
        gender: '',
        usertype: '',
        password: '',
        repassword: ''
      });
      setEditMode(false);
      setEditUserId(null);
    }
    setShowModal(true);
  };
 
  const handleView = (userId) => {
    const userToView = usersData.find(user => user.id === userId);
    if (userToView) {
      setViewUser(userToView);
      setViewModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setEditUserId(null);
  };

  const handleCloseViewModal = () => {
    setViewUser(null);
    setViewModal(false);
  };

  // const handleEdit = (userId) => {
  //   const userToEdit = usersData.find(user => user.id === userId);
  //   if (userToEdit) {
  //     setFormData(userToEdit);
  //     setEditMode(true);
  //     setEditUserId(userId);
  //     handleShowModal();
  //   }
  // };
  const handleEdit = (index) => {
    setEditMode(index);
    const user = usersData[index];
    setFormData({
      username: user.username,
      mobile: user.mobile,
      email: user.email,
      password: user.password,
      repassword: user.repassword, // Pre-fill confirmPassword with existing password  API/v1/api/user
      usertype: user.usertype,
    });
    setShowModal(true); // Show the modal for editing
  };
  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      // const updatedUsers = usersData.filter((_, i) => i !== userId);
      // setUsersData(updatedUsers);
      try {
     const response = await fetch(`https://luxeapi.emedha.in/API/v1/api/user/${userId}`, {
          // const response = await fetch(`http://localhost:3009/api/user/${userId}`, {
        
          method: 'DELETE',
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete user');
        }

        // Remove the user from the local state
        const updatedUsers = usersData.filter(user => user.id !== userId);
        setUsersData(updatedUsers);
        setFilteredUsers(updatedUsers);

        alert('User deleted successfully!');
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filteredResults = usersData.filter(user =>
      user.username.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsers(filteredResults);
  };

  return (
    <>
      <Sidebar />
      <div className="container" id='main'>
        <div className="row justify-content-center mb-3">
          <h3>User Management</h3>
        </div>
        <div className="row justify-content-center mb-3">
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search by User Name..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-lg-6 text-end">
            <Button variant="primary" onClick={() => handleShowModal()}>
              + User Management Form
            </Button>
          </div>
        </div>

        {/* Modal for User Management Form */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? 'Edit User' : 'Add User'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>User Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="mobile">
                <Form.Label>Mobile:</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="gender">
                <Form.Label>Gender:</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="usertype">
                <Form.Label>User Typer:</Form.Label>
                <Form.Control
                  as="select"
                  name="usertype"
                  value={formData.usertype}
                  onChange={handleInputChange}
                  required
                >
                 <option value="">Select User Type</option>
                   <option value="Admin">Admin</option>
                  <option value="Product">Procurement Team</option>
                   {/* <option value="Sales">Sales</option> */}
                  <option value="Bidder">Bidder</option>
                  <option value="WareHouse">WareHouse</option>
                  {/* <option value="Cincinnati">Cincinnati</option>
                 <option value="Raleigh">Raleigh</option>
                   <option value="Dallas">Dallas</option>
                   <option value="Austin">Austin</option> */}
                </Form.Control>
              </Form.Group>

              {!editMode && (
                <>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="repassword">
                    <Form.Label>Re-enter Password:</Form.Label>
                    <Form.Control
                      type="password"
                      name="repassword"
                      value={formData.repassword}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </>
              )}

              <div className="text-center">
                <Button variant="primary" type="submit">
                  {editMode ? 'Update User' : 'Add User'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Modal for Viewing User Details */}
        <Modal show={viewModal} onHide={handleCloseViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>View User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {viewUser && (
              <div>
              <p><strong>User Name:</strong> {viewUser.username}</p>
                 <p><strong>Email:</strong> {viewUser.email}</p>
                <p><strong>Mobile:</strong> {viewUser.mobile}</p>
                 <p><strong>Gender:</strong> {viewUser.gender}</p>
                <p><strong>User Typer:</strong> {viewUser.usertype}</p>
                 {/* <p><strong>Warehouses:</strong>{viewUser.warehouses}</p> */}
                 <p><strong>Password:</strong> {viewUser.password}</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Users Table */}
        
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Gender</th>
              <th>Product Management</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user,index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.mobile}</td>
                <td>{user.gender}</td>
                <td>{user.usertype}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <PiDotsThreeOutlineVerticalBold />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleView(user.id)}>
                        <IoIosEye /> View
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleEdit(user.id)}>
                        <LuClipboardEdit /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDelete(user.id)}>
                        <MdDeleteForever /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default UserManagement;
