import React, { useEffect, useState } from 'react';
import './Sidbar.css'; 
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import { ImHome3 } from "react-icons/im";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa6";
import { RiMessage2Fill } from "react-icons/ri";
import { MdSettings } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { RiAuctionFill } from "react-icons/ri";
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import Home from '../Home/Home';
import Dashboard from '../Home/Dashboard';
import { FcSearch } from "react-icons/fc";
import { RiShieldUserFill } from "react-icons/ri";
import LogoBig from '../images/LogoBig.png';
import AGSTONESLOGO from '../images/AGSTONESLOGO.png';
import AGSTONESLOGO1 from '../images/AGSTONESLOGO1.jpg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { MdWarehouse } from "react-icons/md";
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [usertype, setusertype] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const type = sessionStorage.getItem('usertype');
    setusertype(type);
  }, []);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
   
    console.log('Searching for:', searchTerm);
    
};
 

const usertypes=sessionStorage.getItem('usertype') 
console.log(usertypes)

const handleLogout = () => {
  // Clear session and local storage
  sessionStorage.clear();
  localStorage.removeItem('userData');

  // Redirect to login page
  navigate('/');
};
  return (
    <>
    <div id="mySidebar" className={`sidebar ${isOpen ? '' : 'closed'}` }  >
      <div className="sidebar-header "  >
     <h3> <img src={AGSTONESLOGO1} style={{height:'100px'}}/></h3>
        <button className="toggle-btn "  onClick={toggleNav}> <TbArrowBadgeRightFilled style={{backgroundColor:'transparent',boxShadow: '-11px 10px 15px #f5cc19',margin:'5px', fontSize:'30px' }}/>
          <i className="fas fa-bars"></i>
        </button>
      </div>

      {usertypes === 'Admin' && (
            <>
        <a href="/dashboard"><i className="fas fa-chart-line"><BsGraphUpArrow style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Dashboard</span></a>
        <a href="/userManagement"><i className="fas fa-chart-line"><RiShieldUserFill style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>User Management</span></a>
       <a href="/productManagement"><i className="fas fa-chart-line"><AiFillProduct style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Product Management</span></a>
       <a href="/productMaster"><i className="fas fa-chart-line"><AiFillProduct style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Product Master</span></a>
        {/* <a href="/bidManagement"><i className="fas fa-chart-line"><RiAuctionFill style={{boxShadow: '-11px 10px 15px #f5cc19'}} /></i> <span>Bid Management</span></a> */}
        <a href="/warehouse"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19'}} /></i> <span> WareHouse</span></a>
        {/* <a href="/raleigh"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19'}} /></i> <span>Raleigh</span></a>
        <a href="/dallas"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19'}} /></i> <span>Dallas</span></a>
        <a href="/austin"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19'}} /></i> <span>Austin</span></a> */}
        {/* <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a> */}
            </>
          )}
{/* 
{usertype === 'Account' && (
            <>
       
        <a href="/userManagement"><i className="fas fa-chart-line"><RiShieldUserFill style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>User Management</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )} */}
            {usertypes === 'Product' && (
            <>
             
             <a href="/productManagement"><i className="fas fa-chart-line"><AiFillProduct style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Product Management</span></a>
        <a href="/productMaster"><i className="fas fa-chart-line"><AiFillProduct style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Product Master</span></a>
 
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )}
           {/* {usertypes === 'Bidder' && (
            <>
            

        <a href="/bidManagement"><i className="fas fa-chart-line"><RiAuctionFill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}} /></i> <span>Bid Management</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )} */}
             {usertypes === 'WareHouse' && (
            <>
              

              <a href="/warehouse"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>WareHouse</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )}
              {/* {usertypes === 'Raleigh' && (
            <>
           

       <a href="/raleigh"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}} /></i> <span>Raleigh</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )}
                  {usertypes === 'Dallas' && (
            <>
           

       <a href="/dallas"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}} /></i> <span>Dallas</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )}
                  {usertypes === 'Austin' && (
            <>
           

       <a href="/austin"><i className="fas fa-chart-line"><MdWarehouse style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}} /></i> <span>Austin</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>
        <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>
            </>
          )} */}
       
         {/* <a href="/"><i className="fas fa-chart-line"><BsGraphUpArrow style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Dashboard</span></a>
        <a href="/userManagement"><i className="fas fa-chart-line"><RiShieldUserFill style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>User Management</span></a>
        <a href="/productManagement"><i className="fas fa-chart-line"><AiFillProduct style={{boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Product Management</span></a>
        <a href="/bidManagement"><i className="fas fa-chart-line"><RiAuctionFill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}} /></i> <span>Bid Management</span></a>
        <a href="#"><i className="fas fa-envelope"><RiMessage2Fill style={{boxShadow: '-11px 10px 15px #f5cc19', margin:'5px'}}/></i> <span>Messages</span></a>*/}
        {/* <a href="#"><i className="fas fa-cog"><MdSettings style={{    boxShadow: '-11px 10px 15px #f5cc19'}}/></i> <span>Settings</span></a>   */}
    </ div>
    <div id="main" style={{ boxShadow: '0 0 15px #f5cc19'}} >
     
       
    <Navbar bg="light" data-bs-theme="white">
        <Container>
        <Row>
        <Col xs={12} md={6} lg={7}>
          <Navbar.Brand href="/" style={{color:'#f5cc19'  }}><img src={AGSTONESLOGO1} style={{height:'100px',width:'130px'}}/></Navbar.Brand>
          {/* <Nav className="me-auto">
            <Nav.Link href="#home"  style={{color:'#f5cc19' }}>Home</Nav.Link>
            <Nav.Link href="#features"  style={{color:'#f5cc19'}}>Features</Nav.Link>
            <Nav.Link href="#pricing"  style={{color:'#f5cc19'}}>Pricing</Nav.Link>
            </Nav> */}
                
            </Col>
            <Col xs={12} md={6} lg={5} >
            <div className=' text-right' >
          <div className="  search-box">
          <button className="  btn-search" onClick={handleSearch}  > <FcSearch style={{fontSize:'30px'}}/> </button>
   
            <input
              type="text"
              className="form-control input-search"
              placeholder="Enter search term..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
                 </div>

             
          </div>
          </Col></Row>
          <div className="text-end m-2">
                 <button onClick={handleLogout} className="btn-logout">
        <FaSignOutAlt /> Logout
      </button>
    </div>
          
          </Container>
          </Navbar>  
     
        </div>

   
    </>
  );
};

export default Sidebar;
