import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Sidebar from '../Admin/Sidebar';
import axios from 'axios';


const ProductMaster = () => {
  const [formData, setFormData] = useState({
    newCategory: '',
    newSubcategory: '',
    productName:'',
  });


  const fetchCategories = async () => {
    try {
     const response = await axios.get('https://luxeapi.emedha.in/API/v1/ap/subcategories');
   // const response = await axios.get('http://localhost:3009/ap/subcategories');
     
     setCategoriesList(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {

  fetchCategories();
  
}, []);

  const [categoriesList, setCategoriesList] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


const handleAdd = async () => {
    try {
        const response = await axios.post('https://luxeapi.emedha.in/API/v1/ap/categories', {
    //const response = await axios.post('http://localhost:3009/ap/categories', {
      productName:formData.productName,
     name: formData.newCategory,
        subCategory: formData.newSubcategory,
        
      });
      console.log('Category added:', response.data);
     
      setCategoriesList(prevCategories => [
        ...prevCategories,
        { id: response.data.id, name: formData.newCategory, subCategory: formData.newSubcategory,productName:formData.productName }
      ]);

      // Optionally reset form data or update UI
      setFormData({
        productName:'',
        newCategory: '',
        newSubcategory: ''
      });
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };
  


const handleDeleteCategory = (id) => {
   axios.delete(`https://luxeapi.emedha.in/API/v1/api/categories/${id}`)
     // axios.delete(`http://localhost:3009/api/categories/${id}`)
       
   .then(response => {
        setCategoriesList(prevCategories => prevCategories.filter(category => category.id !== id));
        // localStorage.setItem('categoriesList', JSON.stringify(categoriesList.filter(category => category.name !== name)));
      })
      .catch(error => {
        console.error('Error deleting category:', error);
      });
  };

  console.log(categoriesList)

  return (
   
    <div>
         <Sidebar/>
      <div className="d-flex" id='main'>
      
        <div className="w-100">
       
          <div className='container text-center mt-2' >
            <h4>ProductMaster</h4>
 
            <div className="card-body ">
          
              <select
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={handleChange}
                className="form-control mb-2"
                required
              >
                <option value="">Select Product Type</option>
                <option value="Natural">Natural</option>
                <option value="Engineered">Engineered</option>
                {/* Add more options as needed */}
              </select>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Enter Category"
                name="newCategory"
                value={formData.newCategory}
                onChange={handleChange}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Enter Color/Design"
                name="newSubcategory"
                value={formData.newSubcategory}
                onChange={handleChange}
              />
              <Button className='btn btn-success btn-sm mb-2' onClick={handleAdd}>
                Add Category
              </Button>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col' >Product Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Sub Category</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categoriesList.map(category => (
                    <tr key={category.id}>
                      <td>{category.productName}</td>
                      <td>{category.name}</td>  
                      <td>{category.subCategory}</td>
                      <td>
                        <Button
                          onClick={() => handleDeleteCategory(category.id)}
                          style={{ padding: '1px 10px' }}
                          className='btn-sm'
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMaster;
