import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Carousel } from 'react-bootstrap';
import Sidebar from '../Admin/Sidebar';
import axios from 'axios';
import ModalComponent from './ModalComponent';
const ProductManagement = () => {

  const [formData, setFormData] = useState({
    productName: '',
    product: '',
    buildno: '',
    blockno: '',
    productCategory: '',
    price: '',
    thikness: '',
    uom: '',
    dimension: '',
    dimensionType: '',
    dimensionUnit: '',
    quantity:'',
    note:'',
    availability:'',
    startBidDate:'',
    expireBidDate:'',
    images: {
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      image6: null,
      image7: null,
      image8: null,
      image9: null,
      image10: null,
    }
  });
  
  const [imageCount, setImageCount] = useState(0);
  const [image,setImage]=useState(null)
  const [submittedProducts, setSubmittedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [usertype, setusertype] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);


  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  // Fetch user role from sessionStorage
  
  useEffect(() => {
    const role = sessionStorage.getItem('usertype');
    setusertype(role);
  }, []);


  useEffect(() => {
  const fetchProducts = async () => {
    try {
    const response = await fetch('https://luxeapi.emedha.in/API/v1/api/userpd');
      //  const response = await fetch('http://localhost:3009/api/userpd');
     
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
        setSubmittedProducts(data);
        setFilteredProducts(data); // Initialize filtered data
      setUsersData(data);
     
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };
  fetchProducts();
}, []);

  
const fetchSubcategories = async (categoryName) => {
  try {
  const response = await axios.get('https://luxeapi.emedha.in/API/v1/api/subcategories', {
      // const response = await axios.get('http://localhost:3009/api/subcategories', {
   
      params: { categoryName }
    });
    setSubcategories(response.data);
  } catch (error) {
    console.error('Error fetching subcategories:', error);
  }
};

const [name, setName] = useState('');
  const [subcategoriess, setSubcategoriess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectedCategory === '') return; // Don't fetch if name is empty
      console.log(selectedCategory)

      setLoading(true);
      setError(null);

      try {
            const response = await fetch(`https://luxeapi.emedha.in/API/v1/api/subcategories?name=${cname}`);
             //       const response = await fetch(`http://localhost:3009/api/subcategories?name=${cname}`);
       
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSubcategoriess(data);
        console.log(data)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubcategories();
  }, [selectedCategory]); // Fetch when name changes
 
console.log(selectedCategory)

const [selectedProduct, setSelectedProduct] = useState('');
const [productname, setProductName] = useState([]);

useEffect(() => {
  const fetchProductName = async () => {
    if (selectedProduct === '') return; // Don't fetch if name is empty
    console.log(selectedProduct)

    setLoading(true);
    setError(null);

    try {
        const response = await fetch(`https://luxeapi.emedha.in/API/v1/api/get/subcategory?name=${productName}`);
             //   const response = await fetch(`http://localhost:3009/api/get/subcategory?name=${productName}`);
     
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProductName(data);
      console.log(data)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  fetchProductName();
}, [selectedProduct]);


console.log(productname)

  const [categoriesss, setCategoriesss] = useState([]);
  const [categoriess, setCategoriess] = useState([]);
  
console.log(categoriess)
  useEffect(() => {
    // Fetch categories data from the API
    const fetchCategories = async () => {
      try {
      const response = await axios.get('https://luxeapi.emedha.in/API/v1/ap/subcategories');
          //const response = await axios.get('http://localhost:3009/ap/subcategories');
       
        setCategoriesss(response.data);
        setCategoriess(response.data);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError('Failed to load categories.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);


  useEffect(() => {
    const filteredResults = submittedProducts
      .filter(product => {
        if (usertype === 'Admin') {
          return true; // Admin sees all products
        } else if (usertype === 'Product') {
          // Adjust this condition based on what 'Product' users should see
          return product.product === 'ProductManagement'; // Example condition
        }
        return false; // Default case
      })
      // .filter(product =>
      //   product.product.toLowerCase().includes(searchTerm.toLowerCase())
      // );

    setFilteredProducts(filteredResults);
  }, [searchTerm, submittedProducts, usertype]);

  

  useEffect(() => {
    // Fetch submitted products from localStorage on component mount
    const savedProducts = JSON.parse(localStorage.getItem('submittedProducts')) || [];
    setSubmittedProducts(savedProducts);
    setFilteredProducts(savedProducts); // Initialize filtered data with saved products
  }, []);

  useEffect(() => {
    // Filter products based on search term
    const filteredResults = submittedProducts.filter(product =>
      product.product.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filteredResults);
  }, [searchTerm, submittedProducts]);


  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  

    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const [selection, setSelection] = useState('');
  const [buildno, setBuildno] = useState('');
  const [blockno, setBlockno] = useState('');

const handleProductChange =(e) => {

  setSelection(e.target.value);
  setBuildno('');
  setBlockno('');
  const selected = e.target.value;
  setSelectedProduct(selected);
  localStorage.setItem('productName',selected)
 
  setFormData({
    ...formData,
    productName: e.target.value,
    product: '', // Clear the category when the product changes
      productCategory: '' // Clear the product category when the product changes
  });
   fetchSubcategories(selectedCategory);
}

  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedProduct(selected)
    setSelectedCategory(selected);
    localStorage.setItem('names',selected)
   
    setFormData({
      ...formData,
      product: selected
    });
     fetchSubcategories(selectedCategory);
  };

  const handleSubcategoryChange = (e) => {
   
    const selected = e.target.value;
    setSelectedCategory(selected);
    localStorage.setItem('productCategory',selected)
   
    setFormData({
      ...formData,
      productCategory: e.target.value,
      
     //quartz: e.target.value,
    });
    
  };

  const uniqueCategories = Array.from(new Set(productname.map(item => item.name)))
  .map(name => {
    return productname.find(item => item.name === name);
  });


  const uniqueCategoriess = Array.from(new Set(categoriess.map(item => item.productName)))
  .map(productName => {
    return categoriess.find(item => item.productName === productName);
  });


  const productName=localStorage.getItem('productName')
  console.log(productName)

  const cname=localStorage.getItem('names')
  console.log(cname)

  const productCategory=localStorage.getItem('productCategory')
  console.log(productCategory)


  const [imageFiles, setImageFiles] = useState([]);

  const handleImageCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setImageCount(count);
    setSelectedFiles(prevFiles => {
      if (count > prevFiles.length) {
        return [...prevFiles, ...Array(count - prevFiles.length).fill(null)];
      } else {
        return prevFiles.slice(0, count);
      }
    });
  };
  

  const handleImageChangee = (index, e) => {
    const file = e.target.files[0];
    const updatedImages = [...image];
    updatedImages[index] = file;
    setImage(updatedImages);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [numFiles, setNumFiles] = useState(10); // Default to 10, but can be adjusted
  const [fileInputs, setFileInputs] = useState(Array(10).fill(null));
  const [selectedFiles, setSelectedFiles] = useState(Array(10).fill(null));
  const [submittedImages, setSubmittedImages] = useState([]);
  
  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    const updatedImages = [...image];
    updatedImages[index] = file;
    setImage(updatedImages);
  };
  const handleNumFilesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > 10) {
      alert("You can specify a maximum of 10 files.");
      return;
    }
    setImageCount(value);
    setImage(Array(value).fill(null));
  };

  // Handle file selection
  const handleFileChange = (e, index) => {
    const files = [...selectedFiles];
    files[index] = e.target.files[0]; // Assuming single file per input
    setSelectedFiles(files);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Create a FormData object
    // const formDataToSubmit = new FormData();
  
  
    // for (const [key, value] of Object.entries(formData)) {
    //   formDataToSubmit.append(key, value);
    // }
    // // Append image file if available
    // if (image) {
    //   formDataToSubmit.append('image', image);
    // }

    const formDataToSubmit = new FormData();
    selectedFiles.forEach((file, index) => {
      if (file) formDataToSubmit.append(`image${index + 1}`, file);
    });
;

    // Append other form data
    Object.entries(formData).forEach(([key, value]) => {
     formDataToSubmit.append(key, value); // Ensure non-null values
    });
    console.log('Form Data before submission:', formDataToSubmit);

    try {
         const response = await fetch('https://luxeapi.emedha.in/API/v1/api/users', {
       //const response = await fetch('http://localhost:3009/api/users', {
        method: 'POST',
        body: formDataToSubmit, // Send FormData as the body
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Assuming the backend returns a success message
      const result = await response.json();
      console.log(result.message);
      // setImage(response.data.image || []);
      // Update state with new product (assuming you have state for submitted products)
      //  setSubmittedProducts(prev => [...prev, formDataToSubmit]);
      //  setFilteredProducts(prev => [...prev, formDataToSubmit]);

      alert('Product successfully added!');
      setSubmittedImages(Object.values(result.data.images).filter(url => url !== null));
      // Reset form fields
      setFormData({
        productName: '',
        product: '',
        buildno: '',
        blockno: '',
        productCategory: '',
        price: '',
        thikness: '',
        uom: '',
        dimension: '',
        dimensionType: '',
        dimensionUnit: '',
        quantity:'',
        note:'',
        availability:'',
        startBidDate:'',
        expireBidDate:'',
      });
      setSelectedFiles(Array(10).fill(null));
      // Close the modal after submission (if applicable)
       handleCloseModal();
       window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

// console.log(image);
  const [showImageModal, setShowImageModal] = useState(false);

  // const handleCloseImageModal = () => setShowImageModal(false);

  const [currentImages, setCurrentImages] = useState([]);
  // const handleShowImageModal = (images) => {
  //   setCurrentImages(images);
  //   setShowImageModal(true);
  // };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleShowImageModal = (product) => {
    setSelectedProduct(product);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setSelectedProductId(null);
    setShowImageModal(false);
  };

  const [show, setShow] = useState(false);
  // const [currentImages, setCurrentImages] = useState([]);

  const handleShow = (images) => {
    setCurrentImages(images);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
    <Sidebar/>
    <div  id="main">
      
        <div className="row justify-content-center mb-3">
          <h3>Product Management</h3>
        </div>
        <div className="row justify-content-center mb-3">
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-lg-6 text-end">
            <Button variant="primary" onClick={handleShowModal}>
              + Product Management Form
            </Button>
          </div>
        </div>

        {/* Modal for Product Management Form */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Product Management Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
          
 <div className="mb-3">
        <label htmlFor="productName" className="form-label">
          Product  Type
        </label>
            <select className="form-control"
          required
           value={formData.productName} onChange={handleProductChange} name="productName">
                    <option value="">Select Product Type</option>
                    {uniqueCategoriess.map((item, index) => (
                        <option key={item.value} value={item.productName}>
                          {item.productName}
                          </option>
                    ))}
                  </select>
          
          
    
      </div>
              <div className="mb-3">
        <label htmlFor="product" className="form-label">
          Product Category 
        </label>
  
          <select className="form-control"
          required
           value={formData.product} onChange={handleCategoryChange} name="Category">
                    <option value="">Select Category</option>
                    {uniqueCategories.map((item, index) => (
                        <option key={item.value} value={item.name}>
                          {item.name}
                          </option>
                    ))}
                  </select>
          
          
    
      </div>

        <div className="mb-3">
          <label htmlFor="productCategory" className="form-label">
            Color/Design 
          </label>
          <select
            id="productCategory"
            name="productCategory"
           value={formData.subCategory}
            onChange={handleSubcategoryChange}
            className="form-control"
            required
          >
           
              <option value="">Select Color/Design products</option>
              {subcategoriess.map((item, index) => (
                        <option key={index} value={item.subCategory}>
                          {item.subCategory}
                        </option>
                      ))}
          </select>
        </div>
  

        {selection === 'Natural' && (
        <>
        <div className="mb-3">
            <label htmlFor="blockno" className="form-label">
              Block No.
              </label>
              <input
              id='blockno'
                type="text"
                name="blockno"
                  className="form-control"
                value={formData.blockno}
                maxLength={255}  // Example limit for VARCHAR length
                pattern="[A-Za-z0-9 _-]*"  // Example pattern, adjust as needed
                onChange={handleInputChange}
                required
              />
   
          </div>
          <div className="mb-3">
            <label htmlFor="buildno" className="form-label">
               No.of Bundles
              </label >
              <input
              id="buildno"
                type="text"
                name="buildno"
                className="form-control"
                value={formData.buildno}
                maxLength={255}  // Example limit for VARCHAR length
                pattern="[A-Za-z0-9 _-]*"  // Example pattern, adjust as needed
                onChange={handleInputChange}
                required
              />
         
          </div>
          
        </>
      )}
              <div className="mb-3">
  <label htmlFor="uom" className="form-label">
    Unit of Measurement (UOM)
  </label>
  <select
    id="uom"
    name="uom"
    value={formData.uom}
    onChange={handleInputChange}
    className="form-control"
    required
  >
     <option value="">Select UOM</option>
       <option value="sqft">Square feet</option>
       <option value="sqm">Square meter</option>
  </select>
</div>


              <div className="mb-3">
                <label htmlFor="thikness" className="form-label">
                  Thickness
                </label>
                <input
                  type="text"
                  id="thikness"
                  name="thikness"
                  value={formData.thikness}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>



<div className="mb-3">
      <label htmlFor="dimension" className="form-label">Dimension</label>
      <select
        id="dimension"
        name="dimension"
        value={formData.dimension}
        onChange={handleInputChange}
        className="form-control"
        required
      >
        <option value="">Select unit</option>
        <option value="inches">Inches</option>
        <option value="sqmeter">Centimeter</option>
      </select>

   
        <>
          <label htmlFor="dimensionType" className="form-label">Length</label>
          <input
                type="number"
                 id='dimensionType'
                 name='dimensionType'
                 
                 value={formData.dimensionType}
                onChange={handleInputChange}
                className="form-control"
                maxLength={255}
                // pattern="[A-Za-z0-9 _-]*"  // Numeric input pattern
               // title={`Please enter the ${formData.dimensionType} in ${formData.dimension}`}
                required
></input>
         
            <div>
              <label htmlFor='dimensionUnit' className="form-label">
              Width
              </label>
              <input
                type="number"
                 id="dimensionUnit"
                 name='dimensionUnit'
                 
                 value={formData.dimensionUnit}
                onChange={handleInputChange}
                className="form-control"
                maxLength={255}
                // pattern="[A-Za-z0-9 _-]*"  // Numeric input pattern
               // title={`Please enter the ${formData.dimensionType} in ${formData.dimension}`}
                required
              />
            </div>
         
        </>
    
    </div>
    <div className="mb-3">
                <label htmlFor="quantity" className="form-label">Quantity</label>
                <input type="text" className="form-control" id="quantity" name="quantity" value={formData.quantity} onChange={handleInputChange} required />
              </div>

        <div className="mb-3">
                <label htmlFor="note" className="form-label">Note</label>
                <input   as="textarea"
         rows={3}  className="form-control" id="note" name="note" value={formData.note} onChange={handleInputChange}  />
              </div>
              {/* <div className="mb-3">
  <label htmlFor="availability" className="form-label">
                Availability :
  </label>
  <select
    id="availability"
    name="availability"
    value={formData.availability }
    onChange={handleInputChange}
    className="form-control"
    required
  >
     <option value="">Select Availability</option>
       <option value="Yes">Yes</option>
       <option value="No">No</option>
  </select>
</div> */}
<div className="mb-3">
  <label htmlFor="startBidDate" className="form-label">Offer Start Time</label>
  <input
    type="datetime-local"  // Use datetime-local for both date and time
    className="form-control"
    id="startBidDate"
    name="startBidDate"
    value={formData.startBidDate}
    onChange={handleInputChange}
    required
  />
</div>
<div className="mb-3">
  <label htmlFor="expireBidDate" className="form-label">Offer End Time</label>
  <input
    type="datetime-local"  // Use datetime-local for both date and time
    className="form-control"
    id="expireBidDate"
    name="expireBidDate"
    value={formData.expireBidDate}
    onChange={handleInputChange}
    required
  />
</div>
<div className="mb-3">
                <label htmlFor="price" className="form-label">
                  Price
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
                
        {/* Other form fields */}
        <div className="form-group mb-3">
        <label htmlFor="numFiles">Number of Images to Upload (up to 10)</label>
        <input
          type="number"
          id="numFiles"
          min="1"
          max="10"
          value={imageCount}
          onChange={handleImageCountChange}
          className="form-control"
        />
      </div>

     {/* File inputs for images */}
{[...Array(imageCount)].map((_, index) => (
  <div key={index} className="mb-3">
    <label>Image {index + 1}</label>
    <input
      type="file"
      accept="image/*"
      onChange={(e) => handleFileChange(e, index)}
      className="form-control"
    />
  </div>
))}
     
              <div className="text-center">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
   
 
  <div className="row justify-content-center mt-4 p-3 m-2 mb-3">
    <div className="col-lg-12">
    
          <Table striped bordered hover responsive>
            
            <thead>
              <tr>
              <th>ID</th>
                <th>Product Type</th>
                <th>Product Category</th>
                 <th>Product Color/Design</th>
                
                 <th>Block No.</th>
                 <th>No.of Bundles</th>
                <th>UOM</th>
                <th>Thickness</th>
                <th>Dimension(In/cm)</th>
                <th>Length</th>
                <th>Width </th>
                <th>Quantity(SFT)</th>
                <th>Note</th>
                {/* <th>Availability</th> */}
                <th>Offer Start Time</th>
                <th>Offer End Time</th>
                <th>Price/Sft</th>
               
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={product.id}>
                <td>{index+1}</td>
                <td>{product.productName}</td>
                <td>{product.product}</td>
                <td>{product.productCategory}</td>
               
                  <td>{product.blockno}</td> 
                  <td>{product.buildno}</td> 
                <td>{product.uom}</td>
                {/* <td>{product.colour}</td> */}
                <td>{product.thikness}</td>
                <td>{product.dimension}</td>
                <td>{product.dimensionType}</td>
                <td>{product.dimensionUnit}</td> 

                <td>{product.quantity}</td>
                <td>{product.note}</td>
                {/* <td>{product.availability === 1 ? 'Yes' : 'No'}</td> */}
                <td>{product.startBidDate ? new Date(product.startBidDate).toLocaleString() : 'Not selected'}</td>
                <td>{product.expireBidDate ? new Date(product.expireBidDate).toLocaleString() : 'Not selected'}</td>
                <td>{product.price}</td>
                <td>
                <Button
  variant="primary"
  onClick={() => {
    // Filter out undefined, null, or empty strings from the images array
    const images = [
      product.image1,
      product.image2,
      product.image3,
      product.image4,
      product.image5,
      product.image6,
      product.image7,
      product.image8,
      product.image9,
      product.image10
    ].filter(image => image); // Only keep non-falsy values (i.e., valid image URLs)

    handleShow(images); // Pass the filtered array to handleShow
  }}
>
  Show Gallery
</Button>
              </td>

         
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap">
            {currentImages.map((image, index) => (
              <div key={index} className="p-2" style={{ maxWidth: '200px' }}>
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="img-fluid"
                  style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    
{/* Display message when no matching products */}
{filteredProducts.length === 0 && searchTerm && (
  <div className="row justify-content-center mt-4">
    <div className="col-lg-6">
      <div className="alert alert-info text-center" role="alert">
        No products found.
      </div>
    </div>
  </div>
)}
      </div>
      
    </>
  );
};

export default ProductManagement;
